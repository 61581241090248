// 使用路由懒加载模式高效、提高首屏的渲染效率
import { createRouter,createWebHashHistory } from 'vue-router'
const routes = [

    {
        path: '/',
        component: ()=>import('../AppMain.vue'),
        redirect: '/index',
        children: [
            {
                path:'/index',
                component: ()=>import('../components/Main.vue'),
                meta: {
                    keepAlive: true
                }}, // 首页

            {
                path: '/sa/:cm',
                component:()=>import('../components/Pages/SingleArticle.vue'),
                name: 'singleArticle',
            },
            {
                path: '/mma/:cm',
                component:()=>import('../components/Pages/MultiModuleArticle.vue'),
                name: 'multiModuleArticle',
            },
            {
                path: '/sma/:cm',
                component:()=>import('../components/Pages/SingleModuleArticle.vue'),
                name: 'singleModuleArticle',
            },
            {
                path: '/ba/:cm',
                component:()=>import('../components/Pages/BookArticle.vue'),
                name: 'bookArticle',
            },
            {
                path: '/detail/:cm/:id',
                component:()=>import('../components/Pages/ArticleDetail.vue'),
                name: 'detail',
            },

            {
                path: '/rp',
                component:()=>import('../components/Pages/TestGuide/RegisterProcess.vue')
            },
            {
                path: '/tp',
                component:()=>import('../components/Pages/TestGuide/TestProcess.vue')
            },
            {
                path:'/cap',
                component:()=>import('../components/Pages/TestGuide/CertificateClaimProcess.vue')
            },

            {
                path: '/al/:cm',
                component:()=>import('../components/Pages/ArticleList.vue'),
                name: 'articleList',
            },
            {
                path: '/ial/:cm',
                component:()=>import('../components/Pages/ImgArticleList.vue'),
                name: 'imgArticleList',
            },
            {
                path: '/cq',
                component:()=>import('../components/Pages/CertQuery.vue'),
                name: 'certQuery',
            },
            {
                path: '/cqResult',
                component:()=>import('../components/Pages/CertQueryResult.vue'),
                name: 'certQueryResult',
            },
            //会员单位和汉语推广大使查询
            {
                path: '/infoQuery',
                component:()=>import('../components/Pages/InfoQuery.vue'),
                name: 'infoQuery',
            },
        ]
    },
    {
        path: '/zpHome',
        component: ()=>import('../zpHome.vue'),
        redirect: '/zp',
        children:[
            {
                path: '/zp',
                component:()=>import('../components/Pages/recruitment/index.vue'),
                name: 'recruit',
            },
            {
                path: '/jobDetail/:id',
                component:()=>import('../components/Pages/recruitment/jobDetail.vue'),
                name: 'jobDetail',
            },
            {
                path: '/companyDetail/:id',
                component:()=>import('../components/Pages/recruitment/companyDetail.vue'),
                name: 'companyDetail',
            },
            {
                path: '/jobList',
                component:()=>import('../components/Pages/recruitment/jobList.vue'),
                name: 'jobList',
            },
            {
                path: '/companyList',
                component:()=>import('../components/Pages/recruitment/companyList.vue'),
                name: 'companyList',
            },
        ]
    }

    // {path:'/',redirect:'/index'},



];
const router = createRouter({
    routes,
    history:createWebHashHistory()

});

router.afterEach((to, from, next)=>{
    window.scrollTo(0,0);
})


export default router